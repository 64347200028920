export const indexLoading = function(){
    var progress = 0;
    var timer = setInterval(function(){
        progress += 20 * Math.random();
        progress = progress > 100 ? 100 : progress;
        let result = progress.toFixed(2)
        $("#progress-text").text(result + "%");
        $("#progress-bar").width(result + "%");
        if(progress == 100){
            clearInterval(timer);
            setTimeout(function(){
                $("#progress-text").hide();
                $(".progress").hide();
                $("#hello").fadeIn(3000);
            }, 800);
        }
    }, 200);
}
window.indexLoading = indexLoading;
